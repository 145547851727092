body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo {
  background-image: url(/geja.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 32px;
  margin: 16px;
  width: 76px;
  vertical-align: bottom;
}

.menuWrapper .logo {
  width: calc(100% - 32px);
}

html,
body
{
  min-width: 385px;
}

section section.ant-layout {
  max-width: 1280px;
  margin: 0 auto;
}

.page {
  background: #fff;
  min-height: 160px;
  padding: 24px;
}


.ant-menu-inline.ant-menu-root
{
  border-right: none;
}


.product-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.product-grid .productCard {
  display: inline-block;
  flex: 1 0 30%;
  min-width: 180px;
  max-width: 30%;
  margin-bottom: 16px;
}

.product-grid .productCard img {
  max-width: 100%;
  padding: 2px;
}
.productCard .ant-spin {
  margin: 48px 0 32px;
}
@media (max-width: 440px) {
  .product-grid .productCard {
    min-width: 100%;
  }
}

.shipping .ant-avatar > img {
  object-fit: contain;
}


.product.page .ant-carousel img {
  max-width: 100%;
}

.product.page .slider-mobile
{
  margin-bottom: 18px;
}
.product.page .slider-desktop
{
  display: none;
}
@media (min-width: 768px) {
  .product.page .slider-mobile {
    display: none;
  }
  .product.page .slider-desktop {
    display: block;
  }
}

