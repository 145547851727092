
.product.page .ant-carousel img {
  max-width: 100%;
}

.product.page .slider-mobile
{
  margin-bottom: 18px;
}
.product.page .slider-desktop
{
  display: none;
}
@media (min-width: 768px) {
  .product.page .slider-mobile {
    display: none;
  }
  .product.page .slider-desktop {
    display: block;
  }
}
